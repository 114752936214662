import React from 'react';
import Layout from '../components/Layout';

import tw from 'twin.macro';
// import { graphql, useStaticQuery } from 'gatsby';
// import DesignIllustration from '../images/icons/design-illustration-contact.svg';

import ContactForm from '../components/form/ContactForm';
import Seo from '../components/SEO';

// const contactQuery = graphql`
//   {
//     page: datoCmsContactPage {
//       seoMetaTags {
//         ...GatsbyDatoCmsSeoMetaTags
//       }
//       title
//       hubspotForm
//       hubspotPortal
//     }
//   }
// `;

import { css } from 'styled-components/macro'; //eslint-disable-line

import {
  SectionIntroduction,
  SectionDescription,
  SectionHeading,
} from '../components/misc/Typography';
import {
  Container,
  TwoColumnContainer,
  LeftColumnContainer,
  RightColumnContainer,
} from '../components/misc/Layouts';

const TextContent = tw.div`lg:py-8 text-center md:text-left`;
// const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

const Contact = ({
  intro = 'Contact Us',
  heading = (
    <>
      Feel free to <span tw="text-primary-500">get in touch</span>
      <wbr /> with us.
    </>
  ),
  descriptionContact = 'We do our best to provide the support you need to file your tax and get maximum refund or decrease your tax liability',
}) => {
  // const { page } = useStaticQuery(contactQuery);
  return (
    <Layout>
      <Seo
        title="Contact Us "
        description="Call 204-202-6322. We&#39;re real people, with real tax expertise, answering real phones in many languages, right here in Canada."
      />
      <Container>
        <TwoColumnContainer>
          <LeftColumnContainer>
            <SectionIntroduction>{intro}</SectionIntroduction>
            <SectionHeading>{heading}</SectionHeading>
            <SectionDescription>{descriptionContact}</SectionDescription>
            <TextContent>
              <ContactForm
                hubspotFormId="6a61544a-755b-4e44-9f6f-45a94403d0a5"
                hubspotPortalId="21292637"
                submitButtonText="Send"
                formAction="#"
                successMessage="Thank you for reaching out.We'll get back to you as soon as
                possible."
                failureMessage="Oops! Something went wrong.Ensure you're using a valid email address & try again. "
              />
            </TextContent>
          </LeftColumnContainer>
          <RightColumnContainer>{}</RightColumnContainer>
        </TwoColumnContainer>
      </Container>
    </Layout>
  );
};

export default Contact;
