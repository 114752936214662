import React, { useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';

import { PrimaryButton } from '../misc/Buttons.js';
import Alert from '../misc/Alert.js';

import { useForm } from 'react-hook-form';
import loadingSrc from '../../images/icons/loading.svg';

import fetch from 'isomorphic-unfetch';
import Cookies from 'js-cookie';
import { isEmail } from '../../util/utils.js';
import endpoints from '../../util/endpoints';

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none focus:border-primary-500 font-medium transition duration-300 hocus:border-primary-500 invalid:border-red-500 invalid:text-red-600`;
const Textarea = styled(Input).attrs({ as: 'textarea' })`
  ${tw`h-24`}
`;
const Confirm = styled(ReCAPTCHA)`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const SubmitButton = tw(
  PrimaryButton,
)`inline-block mt-8 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:transform-none  disabled:transition-none disabled:bg-slate-400 disabled:cursor-not-allowed `;

const Contact = (props) => {
  const {
    hubspotPortalId,
    hubspotFormId,
    submitButtonText,
    formAction,
    successMessage,
    failureMessage,
  } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState(true);
  const [valid, setValid] = useState(false);
  const [success, setSuccess] = useState(true);
  const [sendingData, setSendingData] = useState(false);

  const onConfirm = () => setValid(true);

  const handleError = (err) => {
    setShowAlert(true);
    setValid(true);
    setError(err);
    setSendingData(false);
  };
  const handleSuccess = () => {
    setSuccess(true);
    setError(false);
    setSendingData(false);
    setShowAlert(true);
    reset();
  };

  const handleSubmitForm = (data) => {
    const isBrowser = typeof window !== 'undefined';
    const hutk = isBrowser ? Cookies.get('hubspotutk') : null;
    const pageUri = isBrowser ? window.location.href : null;
    const pageName = isBrowser ? document.title : null;

    const postUrl = `${endpoints.contact}/${hubspotPortalId}/${hubspotFormId}`;

    const body = {
      submittedAt: Date.now(),
      fields: [
        {
          name: 'email',
          value: data.email,
        },
        {
          name: 'fullname',
          value: data.fullname,
        },
        {
          name: 'subject',
          value: data.subject,
        },
        {
          name: 'message',
          value: data.message,
        },
      ],
      context: {
        hutk,
        pageUri,
        pageName,
      },
    };
    setSendingData(true);
    fetch(postUrl, {
      method: 'post',
      body: JSON.stringify(body),
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
      }),
    })
      .then((res) => res.json())
      .then(() => handleSuccess())
      .catch((err) => handleError(err));
  };

  return (
    <Form
      action={formAction}
      onSubmit={handleSubmit(handleSubmitForm)}
      loading={sendingData.toString()}
    >
      <>
        {success && showAlert && (
          <Alert message={successMessage} success={success} stateChanger={setShowAlert} />
        )}
        {error && showAlert && (
          <Alert message={failureMessage} error={error} stateChanger={setShowAlert} />
        )}
        <Input
          id="user_email"
          placeholder="Email"
          type="email"
          required="required"
          {...register('email', {
            required: true,
            pattern: { isEmail },
          })}
        />
        {errors.email && <span>Please check the email</span>}
        <Input type="text" name="fullname" placeholder="Full Name" {...register('fullname')} />
        <Input
          type="text"
          name="subject"
          placeholder="Subject: (Help, Support, Feedback etc)"
          required
          {...register('subject', { required: true, minLength: 4 })}
        />
        {errors.subject && <span>This field is required and minLength should be 4</span>}
        <Textarea
          name="message"
          placeholder="Your Message Here"
          {...register('message', { required: true, minLength: 10 })}
          required
        />
        {errors.message && <span>This field is required and minLength should be 10</span>}
        {!sendingData && (
          <Confirm
            sitekey={process.env.GATSBY_GOOGLE_SITE_KEY}
            theme="dark"
            onChange={() => onConfirm()}
          />
        )}

        <SubmitButton disabled={!valid} type="submit">
          {sendingData && (
            <>
              <img src={loadingSrc} tw="inline-block w-6 h-6 mr-2 stroke-current" />
            </>
          )}
          {submitButtonText}
        </SubmitButton>
      </>
    </Form>
  );
};

export default Contact;

Contact.propTypes = {
  hubspotFormId: PropTypes.string.isRequired,
  hubspotPortalId: PropTypes.string.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  formAction: PropTypes.string.isRequired,
  successMessage: PropTypes.string.isRequired,
  failureMessage: PropTypes.string.isRequired,
};
