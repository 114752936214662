// import React from 'react';

export const isEmail = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;

export const isImg = /^http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?/;

// export const getChildrenToRender = (item, i) => {
//   let tag = item.name.indexOf('title') === 0 ? 'h1' : 'div';
//   tag = item.href ? 'a' : tag;
//   let children =
//     typeof item.children === 'string' && item.children.match(isImg)
//       ? React.createElement('img', { src: item.children, alt: 'img' })
//       : item.children;
//   if (item.name.indexOf('button') === 0 && typeof item.children === 'object') {
//     children = React.createElement(Button, {
//       ...item.children,
//     });
//   }
//   return React.createElement(tag, { key: i.toString(), ...item }, children);
// };

// export const numberInBetween = (number, inbetweenArray) => {
//   var min = Math.min.apply(Math, inbetweenArray),
//     max = Math.max.apply(Math, inbetweenArray);
//   return number > min && number < max;
// };

// const stringToFloatConverter = (str, symbol) => {
//   if (symbol) {
//     return parseFloat(str.replace(symbol, ''));
//   }
//   return parseFloat(str);
// };
// const isWordInTheString = (str, word) => {
//   return str.toUpperCase().includes(word);
// };
// const dataRestructingFromStringtoArray = (arr, currencySymbol, numberSperator, rangeSperator) => {
//   var splitIncomeTaxRange = arr[0].split(rangeSperator);
//   var rate = stringToFloatConverter(arr[1], '%');
//   // console.log('split Aee', rate, splitIncomeTaxRange);
//   if (splitIncomeTaxRange.length < 2) {
//     var splitWordIncome = splitIncomeTaxRange[0].split(currencySymbol);
//     var income = stringToFloatConverter(splitWordIncome[1], numberSperator);
//     if (
//       isWordInTheString(splitWordIncome[0], 'LESS') ||
//       isWordInTheString(splitWordIncome[0], 'UNDER')
//     ) {
//       // console.log('inside resturing range less', income);
//       return { minIncome: 0, maxIncome: income, rate: rate };
//     } else {
//       {
//         return { minIncome: income, maxIncome: 10000000, rate: rate };
//       }
//     }
//   }
//   if (splitIncomeTaxRange.length == 2) {
//     var min = stringToFloatConverter(
//       splitIncomeTaxRange[0].replace(currencySymbol, ''),
//       numberSperator,
//     );
//     var max = stringToFloatConverter(
//       splitIncomeTaxRange[1].replace(currencySymbol, ''),
//       numberSperator,
//     );
//     return { minIncome: min, maxIncome: max, rate: rate };
//   }
// };

// // const taxBracketDataConverter = (
// //   arr,
// //   currencySymbol = '$',
// //   numberSperator = ',',
// //   rangeSperator = 'up to',
// // ) => {
// //   var basicTaxAmount = 0;
// //   var previousTaxBracketRate = 0;
// //   var previousTaxBracketIncome = 0;

// //   var restucturedArray = arr.map((row) => {
// //     return dataRestructingFromStringtoArray(row, currencySymbol, numberSperator, rangeSperator);
// //   });
// //   const copy = [...restucturedArray];

// //   copy.forEach((element, index) => {
// //     //   console.log('inside each');
// //     //   console.log(element);
// //     var minIncomeFromBracketRange = element.minIncome;
// //     var maxIncomeFromBracketRange = element.maxIncome;

// //     var currentTaxBracketRate = element.rate;

// //     basicTaxAmount = basicTaxAmount + previousTaxBracketIncome * (previousTaxBracketRate * 0.01);
// //     previousTaxBracketRate = currentTaxBracketRate;
// //     previousTaxBracketIncome = maxIncomeFromBracketRange - minIncomeFromBracketRange;
// //     console.log(typeof restucturedArray);

// //     restucturedArray[index].baseTaxAmount = basicTaxAmount;
// //   });
// //   return restucturedArray;
// // };
// // //
